import '@/styles/global.scss'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import '@/styles/moveable.css'
import '@/component/RichTextEditor/editor.css'

import { SidebarDrawerProvider } from '@/Context/SidebarDrawerContext'
import AlertCookies from '@/component/AlertCookies'
import { queryClient } from '@/services/queryClient'
import { getAppTheme } from '@/styles/theme'
import { createStandaloneToast } from '@chakra-ui/toast'
import { ChakraProvider } from '@chakra-ui/react'
import { Hydrate, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import App, { AppContext, AppInitialProps, AppProps } from 'next/app'
import NextNProgress from 'nextjs-progressbar'
import { ReactNode, useRef } from 'react'
import { SocketProvider } from '@/Context/Socket'
import { SessionProvider } from 'next-auth/react'
import { ImageGenerateProvider } from '@/Context/ImageStylesPromptContext'
import { getSubdomain } from '@/services/subdomain'
import { api } from '@/services/api'
import { Tenants } from '@/interfaces/Tenants'
import { DEFAULT_TENANT } from '@/utils/contants'
import { NextPage } from 'next'
import { ReactElement } from 'react-markdown/lib/react-markdown'

const { ToastContainer } = createStandaloneToast()

type AppOwnProps = {
  tenant: Tenants
}
export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

export default function MyApp({
  Component,
  pageProps: { session, ...pageProps },
  tenant = DEFAULT_TENANT
}: AppPropsWithLayout & AppOwnProps) {
  const queryRef = useRef(queryClient)
  const getLayout = Component.getLayout ?? ((page) => page)

  return (
    <SessionProvider
      session={session}
      // basePath="/dashboard"
      // refetchInterval={10}
      // refetchOnWindowFocus={true}
    >
      <QueryClientProvider client={queryRef.current}>
        <SocketProvider>
          <ChakraProvider
            theme={getAppTheme({
              appColorsDark: tenant.theme_dark,
              appColorsLight: tenant.theme
            })}
          >
            <ToastContainer />
            <NextNProgress
              nonce={''}
              color={'#D9E2FF'}
              startPosition={0.3}
              stopDelayMs={300}
              height={4}
              showOnShallow={false}
              options={{ easing: 'ease', speed: 500 }}
            />
            <SidebarDrawerProvider>
              <ImageGenerateProvider>
                <Hydrate state={pageProps.dehydratedState}>
                  {getLayout(<Component {...pageProps} />)}
                </Hydrate>
              </ImageGenerateProvider>
            </SidebarDrawerProvider>
            {/* <AlertMessage /> */}
            <AlertCookies />
          </ChakraProvider>
          <ReactQueryDevtools />
        </SocketProvider>
      </QueryClientProvider>
    </SessionProvider>
  )
}

MyApp.getInitialProps = async (
  context: AppContext
): Promise<AppInitialProps & AppOwnProps> => {
  const ctx = await App.getInitialProps(context)
  const jwt = process.env.API_TOKEN
  const host = context.ctx.req?.headers.host
  const subdomain = getSubdomain(host as string)

  if (
    subdomain === 'gptmax' ||
    subdomain === 'development' ||
    subdomain === 'localhost:3002' ||
    !subdomain
  ) {
    return {
      ...ctx,
      tenant: DEFAULT_TENANT
    }
  } else {
    try {
      const { data } = await api.get<Tenants>(
        `/api/tenants/subdomain/${subdomain}/`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        }
      )

      return {
        ...ctx,
        tenant: data ?? DEFAULT_TENANT
      }
    } catch (err) {
      return {
        ...ctx,
        tenant: DEFAULT_TENANT
      }
    }
  }
}
